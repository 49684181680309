<template>
  <v-row>
    <!-- fixed header -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Liste des formulaires</v-card-title>
        <v-card       class="pa-md-4"
>
            <div class="d-flex justify-space-between flex-wrap mx-auto">
              <v-btn color="primary" :to='{name: "Form"}'>
                Créer un formulaire
              </v-btn>
            </div>
              </v-card>

        <table-form></table-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCartPlus } from '@mdi/js'
import TableForm from './TableForm.vue'

export default {
  components: {
    TableForm
  },
  setup() {
    return {
            icons: {
        mdiCartPlus
      },
    }
  },
}
</script>
