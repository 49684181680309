<template>
  <div>
    <v-simple-table height="auto" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              ID Form
            </th>
            <th class="text-center text-uppercase">
              ID Project Planning
            </th>
            <th class="text-center text-uppercase">
              Nom du formulaire (Planning)
            </th>
            <th class="text-center text-uppercase">
              Id Modèle CraftMyPDF
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in forms" :key="item.form">
            <td class="text-center">
              {{ item.idForm }}
            </td>
            <td class="text-center">
              {{ item.idApplicationName }}
            </td>
            <td class="text-center">
              {{ item.libelleForm }}
            </td>
            <td class="text-center">
              {{ item.idTemplateCraftMyPDF }}
            </td>
            <td class="text-center">
              <router-link :to="`/form/${item.idForm}`">
                <v-icon size="15">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </router-link>
              &nbsp;
              <a href="#" @click="showDeleteDialog(item.idForm)">
                <v-icon size="15">
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </a>
            </td>
          </tr>
        </tbody>
        <p class="text-center">{{ message }}</p>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>Suppression</v-card-title>
        <v-card-text
          >êtes-vous sûr de vouloir supprimer le formulaire : `{{ itemToDelete }}`?
          <br />
          <v-alert
            style="margin-top:20px;margin-bottom:0px"
            color="error"
            v-show="message"
            dense
            dismissible
            outlined
            prominent
            type="error"
            >{{ message }}</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogDelete = false">Annuler</v-btn>
          <v-btn color="primary" text @click="deleteForm(itemToDelete)">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPencilOutline, mdiTrashCan } from '@mdi/js'
import FormAPI from '../../api/FormAPI'

export default {
  methods: {
    retrieveForms() {
      FormAPI.getForms()
        .then(response => {
          this.forms = response.data.results
          console.log(this.forms)
        })
        .catch(e => {
          this.message = e
        })
    },
    deleteForm(idApplicationName) {
      FormAPI.deleteForms(idApplicationName)
        .then(response => {
          this.retrieveForms()
          this.dialogDelete = !this.dialogDelete
        })
        .catch(e => {
          console.log(e)
          this.message = (e && e.message) || 'Erreur inconnu, veuillez réessayer ultérieurement'
        })
    },
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.dialogDelete = !this.dialogDelete
    },
  },
  data() {
    return {
      forms: [],
      message: '',
      icons: {
        mdiPencilOutline,
        mdiTrashCan,
      },
      dialogDelete: false,
      itemToDelete: {},
    }
  },
  created() {
    this.retrieveForms()
  },
}
</script>
